// import React, { useState } from "react";
// import Navbar from "./components/Navbar/Navbar";
// import Footer from "./components/Footer/Footer";
// import AboutOss from './components/pages/AboutOss/AboutOss';
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import "./App.css";
// import Home from "./components/pages/Home";
// import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
// import Villkor from "./components/Villkor/Villkor";
// import ScrollToTop from "./components/ScrollToTop/ScrollToTop";


// function App() {
//   const [isAdmin, setIsAdmin] = useState(false);

//   return (
//     <Router>
//        <ScrollToTop />
//       <div className="app-container">
//         <Navbar />
//         <main className="main-content">
//           <Routes>
//             <Route path="/" element={<Home />} />
//             <Route path="/AboutOss" element={<AboutOss />} />
//             <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
//             <Route path="/terms" element={<Villkor />} />
//           </Routes>
//         </main>
//         <Footer />
//       </div>
//     </Router>
//   );
// }

// export default App;